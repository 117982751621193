import Layout from 'components/Layout/Layout';
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import AddOrEditDoctor from 'components/AddDoctor/AddDoctor';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import snackbar from 'utils/snackbar';
import { snackbarErrorMsg } from 'config/constants';
import EmptyState from 'components/EmptyState/EmptyState';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AddIcon from '@material-ui/icons/Add';

const base = process.env.REACT_APP_BASE_URL;

function Dashboard() {
  const [doctors, setDoctors] = useState([]);
  const [doctorsLoading, setDoctorsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(undefined);
  const up600 = useMediaQuery('(min-width:600px)');

  const deleteDoctor = async id => {
    try {
      await axios.delete(`${base}/doctors/${id}`);
      setDoctors(st => st.filter(({ id: doctorId }) => doctorId !== id));
      snackbar.toast('Doctor Deleted');
    } catch (error) {
      snackbar.error(snackbarErrorMsg);
      console.log(error);
    }
  };

  const fetchDoctors = async () => {
    try {
      const { data } = await axios.get(`${base}/doctors`);
      setDoctors(data);
    } catch (error) {
      console.log(error);
    } finally {
      setDoctorsLoading(false);
    }
  };

  useEffect(() => {
    setDoctorsLoading(true);
    fetchDoctors();
  }, []);

  const ctaButton = (
    <Button
      variant="contained"
      size={up600 ? 'large' : 'small'}
      sx={{
        minWidth: 'unset',
        py: 1,
        bgcolor: '#fff',
        color: 'primary.main',
        ':hover': {
          bgcolor: '#fff',
        },
      }}
      onClick={() => {
        setSelectedDoctor(undefined);
        setOpen(true);
      }}
    >
      {up600 ? 'Add Doctor' : <AddIcon />}
    </Button>
  );

  return (
    <Layout cta={ctaButton}>
      <AddOrEditDoctor
        open={open}
        setOpen={setOpen}
        doctor={selectedDoctor}
        setDoctors={setDoctors}
      />
      {doctorsLoading && <CircularProgress color="primary" size={80} sx={{ mx: 'auto' }} />}

      <Collapse in={!doctorsLoading && !!doctors?.length} timeout={500} mountOnEnter unmountOnExit>
        <div>
          <Paper>
            <List sx={{ width: '100%' }}>
              {doctors.map(
                (
                  {
                    image,
                    name,
                    name_ar = '',
                    services,
                    services_ar = [],
                    title,
                    title_ar = '',
                    id,
                  },
                  i,
                  arr,
                ) => (
                  <ListItem key={id} divider={i < arr.length - 1}>
                    <ListItemAvatar>
                      <Avatar alt={name} src={image.url} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${name} ${name_ar}`}
                      secondary={`${title} ${title_ar}`}
                      sx={{ flex: 'unset' }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                      }}
                    >
                      {services.concat(services_ar).map((service, i) => (
                        <Chip key={i} label={service} sx={{ m: 0.4 }} />
                      ))}
                    </Box>
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          deleteDoctor(id);
                        }}
                        edge="end"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedDoctor(doctors[i]);
                          setOpen(true);
                        }}
                        edge="end"
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ),
              )}
            </List>
          </Paper>
        </div>
      </Collapse>
      <Collapse
        in={!doctorsLoading && doctors?.length === 0}
        timeout={700}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <EmptyState size={24} paper message="You haven't added any doctors" />
        </div>
      </Collapse>
    </Layout>
  );
}

export default Dashboard;
