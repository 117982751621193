import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  progress: 0,
  isUploading: false,
};

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  startUpload: state => ({
    ...state,
    isUploading: true,
    progress: 0,
  }),
  endUpload: state => ({
    ...state,
    isUploading: false,
    progress: 0,
  }),
  setProgress: (state, action) => ({
    ...state,
    progress: action.payload,
  }),
});

export const { startUpload, endUpload, setProgress } = uploadSlice.actions;
export default uploadSlice.reducer;
