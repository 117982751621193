import authReducer from './auth.slice';
import uploadReducer from './upload.slice';
import MessagesReducer from './messagesSlice';
const rootReducer = {
  messages: MessagesReducer,
  auth: authReducer,
  upload: uploadReducer,
};

export default rootReducer;
