import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import snackbar from 'utils/snackbar';
import { snackbarErrorMsg } from 'config/constants';
import CircularProgress from '@material-ui/core/CircularProgress';

function Contact({ locale }) {
  const base = process.env.REACT_APP_BASE_URL;
  const [contentLoading, setContentLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleTitle = e => {
    const val = e.target.value;
    setTitle(val);
  };

  const handleDescription = e => {
    const val = e.target.value;
    setDescription(val);
  };

  const fetchConent = async () => {
    try {
      setContentLoading(true);
      const { data } = await axios.get(`${base}/contactDetails`, { params: { locale } });
      const { title, description } = data;
      setTitle(title);
      setDescription(description);
    } catch (error) {
      console.log({ error });
    } finally {
      setContentLoading(false);
    }
  };

  useEffect(() => {
    fetchConent();
  }, []);

  const handleSubmit = async () => {
    try {
      /**
       * Add Create Doctor Here
       */
      await axios.post(`${process.env.REACT_APP_BASE_URL}/contactDetails`, {
        title,
        description,
        locale,
      });
      snackbar.toast('Data Saved Successfully');
      await fetchConent();
    } catch (error) {
      console.log(error);
      snackbar.error(snackbarErrorMsg);
    }
  };

  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 3 },
        display: 'flex',
        flexDirection: 'column',
      }}
      autoComplete="off"
    >
      {contentLoading ? (
        <CircularProgress color="primary" size={80} sx={{ mx: 'auto' }} />
      ) : (
        <>
          <Box
            sx={{
              '& .MuiTextField-root': { m: 3, width: '50%' },
              display: 'flex',
              flexDirection: 'row',
            }}
            autoComplete="off"
          >
            <TextField
              required
              id="outlined-required-title"
              label="Title"
              placeholder="Title"
              multiline
              rows={3}
              value={title}
              onChange={handleTitle}
            />
            <TextField
              required
              multiline
              onChange={handleDescription}
              value={description}
              rows={3}
              id="outlined-required-description"
              label="Description"
              placeholder="Description"
            />
          </Box>

          <Divider />
          <CardActions sx={{ alignSelf: 'flex-end', mt: 2 }}>
            <Button
              size="large"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={!title || !description}
            >
              Save
            </Button>
          </CardActions>
        </>
      )}
    </Box>
  );
}

export default Contact;
