import * as React from 'react';

function SvgKashfLogo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={79.766} height={85.821} {...props}>
      <defs>
        <style>
          {
            '.kashf-logo_svg__a,.kashf-logo_svg__f{fill:#ef7d00}.kashf-logo_svg__e{fill:#2192b6}.kashf-logo_svg__e,.kashf-logo_svg__f{fill-rule:evenodd}'
          }
        </style>
      </defs>
      <path
        className="kashf-logo_svg__a"
        d="M69.107 60.776l.013-.014a.02.02 0 00-.008.006l-.005.009M72.73 23.366l-.01-8.477h-.045v-.651A14.659 14.659 0 0058.155.008C46.064-.011 33.972.026 21.881 0a13.913 13.913 0 00-6.838 1.616c-4.776 2.592-7.625 6.391-7.975 11.725h-.033V14.449q-.013 9.239-.008 18.475T7.035 51.4v1.107h.033c.35 5.335 3.2 9.132 7.975 11.725a13.912 13.912 0 006.838 1.615q10.761-.022 21.524 0l-3.738-2.32-7.622-9.637a24.754 24.754 0 1125.549-40.713l.068-.189 15.066 10.416V42.487q.017-4.778.012-9.557t-.012-9.557"
      />
      <path
        d="M57.662 12.985l-.068.189a24.757 24.757 0 01-10.392 40.827l-7.534 9.522 3.739 2.32h14.749a14.672 14.672 0 0010.951-5.064l.006-.009.008-.005a14.126 14.126 0 003.55-9.152c0-.217 0-.434.006-.65h.045q0-3.381.008-6.76v-20.8z"
        fill="#e45008"
      />
      <path
        d="M57.594 13.174a24.753 24.753 0 10-25.549 40.712l7.624 9.634L47.203 54a24.756 24.756 0 0010.392-40.827"
        fill="#fcfcfb"
      />
      <path
        d="M43.012 28.204c3.333-2.523 5.468-7.927 5.468-7.927l-1.941-.745a17.773 17.773 0 01-4.53 6.73 17.161 17.161 0 01-7.7 4.288V19.792l-2.3.129v26.356h2.3v-13.6l.032.03c2.007-.259 7.443-2.913 7.443-2.913 3.235 2.686 3.526 5.015 3.526 5.015l.032 11c.033 1 1.165.97 1.165.97a1.014 1.014 0 00.938-1V35.323c-.032-4.27-4.433-7.119-4.433-7.119"
        fill="#0d93bc"
      />
      <path
        className="kashf-logo_svg__a"
        d="M34.726 45.775a1.586 1.586 0 10-1.585 1.585 1.585 1.585 0 001.585-1.585M33.11 16.062h-.03c-1.33.023-1.523 1.47-1.523 1.47v1.566s.193 1.447 1.523 1.47h.03c1.33-.023 1.521-1.47 1.521-1.47v-1.564s-.192-1.447-1.521-1.47M48.54 16.19a.058.058 0 00-.016 0h-.014a1.66 1.66 0 00-1.818 1.084l-.153.657-.049.21-.153.657a1.661 1.661 0 001.145 1.78h.032a1.659 1.659 0 001.816-1.084l.177-.752v-.021l.176-.751a1.66 1.66 0 00-1.145-1.778"
      />
      <path
        className="kashf-logo_svg__a"
        d="M33.154 49.439a3.751 3.751 0 01-2.316-6.7.517.517 0 11.638.813 2.719 2.719 0 103.366.007.516.516 0 11.641-.809 3.752 3.752 0 01-2.328 6.693"
      />
      <path
        className="kashf-logo_svg__e"
        d="M7.405 84.916v-3.551c-.1-1.194-1.743-2.647-1.743-2.647a4.293 4.293 0 001.445-3.132l.008-1.227a.8.8 0 00-.708-.937c-.742 0-.745.968-.745.968v1.064c-.332 2.654-2.166 2.649-2.166 2.649h-1.84v-3.754a.863.863 0 00-.818-.895.877.877 0 00-.84.894v10.611s.193.671.777.746a.58.58 0 00.106 0c.585-.073.776-.746.776-.746v-5.113H3.82a2.213 2.213 0 012.13 1.807v3.293a.71.71 0 00.7.737.74.74 0 00.753-.769M15.185 77.216h-4.326a2.185 2.185 0 00-1.646 2.39v5.31a.787.787 0 00.775.77.731.731 0 00.71-.738v-2.066h3.81v2.1a.818.818 0 00.771.711.8.8 0 00.778-.711v-6.845a1.046 1.046 0 00-.872-.919m-.9 3.212c.017.636-.324.662-.324.662H11.23c-.6.016-.517-.936-.517-.936.017-1.179.9-1.163.9-1.163h2.664zM19.934 77.254h3.724s.945-.038.989.818a.854.854 0 01-.861.925h-3.252s-.883.022-.9.755a.771.771 0 00.8.731h2.217a2.265 2.265 0 012.022 2.39 2.634 2.634 0 01-2.854 2.776h-3.086a.939.939 0 01-.871-.9.951.951 0 01.828-.968h3.229a.8.8 0 00.71-.829s0-.57-.731-.7h-2.282s-1.818-.161-1.786-2.519c0 0 0-2.313 2.109-2.475M26.167 74.263v10.543a1.049 1.049 0 00.979.865s.826-.116.806-.826v-5.262s.052-.606.9-.6h2.055a.921.921 0 01.633.614v5.109s.206.939.9.96a.847.847 0 00.845-.845v-5.915s.078-1.594-1.843-1.672h-3.515v-3a1.054 1.054 0 00-.9-.826.888.888 0 00-.864.845M37.231 73.418h1.594a1.036 1.036 0 011.037 1.018s.038.9-.788.978h-1.4s-.5-.057-.5.653v2.324h1.709s.942.23.961.788c0 0 .23.749-.846 1h-1.843v4.4s.129 1.113-.865 1.114a1.114 1.114 0 01-.9-1.171v-8.758s.058-2.093 1.844-2.343"
      />
      <path
        className="kashf-logo_svg__f"
        d="M46.678 74.532a1.019 1.019 0 001.037.883h3.648s.634.058.692.9v2.324s-.1.577-1.057.653h-1.364a2.889 2.889 0 00-2.938 2.689v2.981s-.024.728.864.729h5.532a1.081 1.081 0 001.037-1.036s.038-.846-1.075-.884h-3.861a.462.462 0 01-.422-.461v-1s-.135-1.114 2.094-1.191h.787s2.478.058 2.44-2.092v-3.553a2.171 2.171 0 00-2.171-2.037h-4.36a1.036 1.036 0 00-.882 1.1M55.596 74.412a1.2 1.2 0 011.119-.989 1.156 1.156 0 01.969 1.118v3.918a.86.86 0 00.947.839h2.367v-4.886a.991.991 0 011.033-.968 1.038 1.038 0 011.012.947v10.268s-.013 1.033-.947 1.033c0 0-.989.065-1.12-1.141v-3.425h-3.055a2.409 2.409 0 01-2.282-2.109z"
      />
      <path
        d="M66.828 85.82a.777.777 0 01-.732-1.03l3.767-10.847a.775.775 0 111.464.508l-3.766 10.848a.775.775 0 01-.732.521"
        fill="#2192b6"
      />
      <path
        className="kashf-logo_svg__a"
        d="M78.733 85.691a1.033 1.033 0 01-1.033-1.033v-8.763a7.912 7.912 0 00-2.627-.439v.7a1.033 1.033 0 11-2.065 0v-2.751l1.007-.026a19.218 19.218 0 012.359.109c1.269.146 2.995.489 3.355 1.791l.037.135v9.244a1.033 1.033 0 01-1.033 1.033"
      />
    </svg>
  );
}

export default SvgKashfLogo;
