import {
  startAuth,
  logoutUser,
  checkAuth,
  authUserSuccess,
  authFail,
} from 'redux/features/auth.slice';
import { put, call, fork, takeLatest } from 'redux-saga/effects';
import snackbar from 'utils/snackbar';
import { setUserSentry } from 'config/sentry';
import firebase from 'config/firebase';

const signInWithEmailAndPassword = (email, password) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

const onAuthStateChanged = () => {
  return new Promise(resolve => {
    firebase.auth().onAuthStateChanged(user => {
      resolve(user);
    });
  });
};

const signOut = () => firebase.auth().signOut();

function* handleAuthUser(action) {
  try {
    // User is logging into his account
    const { email, password } = yield action.payload.fields;
    yield call(signInWithEmailAndPassword, email, password);
    yield put(checkAuth());
  } catch (error) {
    switch (error.code) {
      // Handle error when user tries login with incorrect email
      case 'auth/user-not-found':
        yield put(
          authFail({ email: 'The email address that you’ve entered doesn’t match any account.' }),
        );
        break;
      // Handle error when user tries login with incorrect password
      case 'auth/wrong-password':
        yield put(authFail({ password: 'The password that you’ve entered is incorrect.' }));
        break;
      default:
        yield console.log(error);
        break;
    }
  }
}

function* handleLogoutUser() {
  yield localStorage.removeItem('isAuth');
  yield call(signOut);
}

// eslint-disable-next-line consistent-return
function* handleCheckAuth() {
  try {
    const user = yield call(onAuthStateChanged);
    if (!user) return yield put(logoutUser());
    yield fork(setUserSentry, user.uid, user.email);
    yield put(authUserSuccess(user));
    yield localStorage.setItem('isAuth', 1);
  } catch (error) {
    switch (error.code) {
      // // Handle error when user's account doesn't exist anymore
      // case 'UserNotFoundException':
      //   yield put(logoutUser());
      //   break;
      case 'NetworkError':
        yield snackbar.error(`Something went wrong while fetching your data.`);
        break;
      default:
        console.log(error);
        break;
    }
  }
}

export default function* watchAuth() {
  yield takeLatest(startAuth.toString(), handleAuthUser);
  yield takeLatest(logoutUser.toString(), handleLogoutUser);
  yield takeLatest(checkAuth.toString(), handleCheckAuth);
}
