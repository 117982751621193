import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const loadMessages = createAsyncThunk(
  'messages/load',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const base = process.env.REACT_APP_BASE_URL;

      const { messages } = getState();
      const params = {};
      if (messages.messages.length) {
        params.before = messages.messages[messages.messages.length - 1].created_at;
      }
      const { data } = await axios.get(`${base}/messages`, { params });
      return data.messages;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    loading: true,
    error: undefined,
    messages: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadMessages.pending, (state, action) => {
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(loadMessages.fulfilled, (state, action) => {
      state.messages = [...state.messages,...action.payload]
      state.error = undefined;
      state.loading = false;
    });
    builder.addCase(loadMessages.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default messagesSlice.reducer;
