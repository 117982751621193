import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadMessages } from 'redux/features/messagesSlice';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Layout from 'components/Layout/Layout';
import Typography from '@material-ui/core/Typography';

function Messages() {
  const dispatch = useDispatch();
  const state = useSelector(state => state.messages);
  console.log(state);
  const loadMore = () => {
    dispatch(loadMessages());
  };
  useEffect(() => {
    loadMore();
  }, []);

  useEffect(() => {
    if (state.error) alert(state.error);
  }, [state.error]);

  return (
    <Layout>
      <Typography sx={{ mb: '20px' }} align="left" variant="h5">
        Contact Messages
      </Typography>
      {state.loading ? (
        <CircularProgress color="primary" size={80} sx={{ mx: 'auto', my: 'auto' }} />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Messages Table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="left">Subject</TableCell>
              <TableCell align="left">Message</TableCell>
              <TableCell align="left">Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.messages.map(row => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>
                <TableCell align="left">{row.subject}</TableCell>
                <TableCell align="left">{row.message}</TableCell>
                <TableCell align="left">{new Date(row.created_at).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button sx={{ mt: '10px' }} disabled={state.loading} onClick={() => loadMore()}>
        Load More
      </Button>
    </Layout>
  );
}

export default Messages;
