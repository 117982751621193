import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import firebase from 'config/firebase';
import snackbar from 'utils/snackbar';
import { snackbarErrorMsg } from 'config/constants';
import {
  Alert,
  CardContent,
  CircularProgress,
  Collapse,
  Dialog,
  Typography,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { endUpload, setProgress, startUpload } from 'redux/features/upload.slice';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import _ from 'lodash';

const ImageCardComponent = ({ file, url, removeImage, title, component, type }) => {
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  return (
    <Card sx={{ maxWidth: 300, width: 300, m: 2 }}>
      <CardMedia sx={{ height: 140 }} src={url} component={component} title="About Image" />
      {title && (
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {title}
          </Typography>
        </CardContent>
      )}
      <CardActions>
        <Button size="small" onClick={() => setWarningDialogOpen(true)}>
          Remove
        </Button>
      </CardActions>
      <WarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        containedBtn="close"
        onProceed={() => removeImage(file, type)}
        title={`Remove ${type}?`}
        description={`You are about to Remove ${type}, this action is irreversible.`}
        closeLabel="Cancel"
        proceedLabel="Yes, Remove!"
      />
    </Card>
  );
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px 20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: 'rgba(0,0,0,60)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const AddImageModal = ({ open, handleClose, features, handleSubmit }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');

  const handleTitle = e => setTitle(e.target.value);

  const file = `about/${new Date().getTime()}.${image?.name.split('.').pop()}`;

  const uploadImage = () => {
    dispatch(startUpload());
    const uploadTask = firebase.storage().ref(file).put(image);
    uploadTask.on(
      'state_changed',
      snapshot => {
        // progrss function ....
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        dispatch(setProgress(progress));
      },
      error => {
        // error function ....
        snackbar.error('Error Occured While Uploading.');
        console.log(error);
      },
      async () => {
        // complete function ....
        const bucket = firebase.storage().ref().bucket;
        const url = await (await uploadTask).ref.getDownloadURL();
        features.push({ title, image: { bucket, file, url } });
        await handleSubmit({ featuresArr: features });
        dispatch(endUpload());
        handleClose();
      },
    );
  };

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setImage(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          alignItems: 'center',
        },
      }}
    >
      <Box
        sx={{
          '& .MuiTextField-root': { width: '100%' },
          display: 'flex',
          flexDirection: 'column',
        }}
        autoComplete="off"
      >
        <TextField
          sx={{ my: 2 }}
          required
          onChange={handleTitle}
          value={title}
          id="outlined-required-title"
          label="Title"
          placeholder="Title"
        />
        <Box sx={{ mb: 2, minWidth: { md: 520 } }}>
          <Collapse in={!image} timeout={400} mountOnEnter unmountOnExit>
            <div className="container">
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the image here ...</p>
                ) : (
                  <p>Drag 'n' drop image here, or click to select it</p>
                )}
              </div>
            </div>
          </Collapse>
          <Collapse in={!!image} timeout={400} mountOnEnter unmountOnExit>
            <Alert
              severity="success"
              sx={{
                border: '1px solid',
                borderColor: 'rgba(0,0,0,0.08)',
                boxShadow: 1,
                p: 2,
                color: 'text.primary',
                '& .MuiAlert-message': {
                  color: 'inherit',
                  fontWeight: 'bold',
                },
                '& .MuiAlert-icon': {
                  color: 'inherit',
                },
                '&:hover': {
                  boxShadow: 3,
                },
              }}
              variant="outlined"
              onClose={() => {
                setImage(null);
                setTitle('');
              }}
            >
              {image?.name}
            </Alert>
          </Collapse>
        </Box>
      </Box>
      <CardActions>
        <Button
          size="large"
          variant="contained"
          onClick={() => uploadImage()}
          disabled={!image || !title}
        >
          Upload
        </Button>
      </CardActions>
    </Dialog>
  );
};

function About({ locale }) {
  const base = process.env.REACT_APP_BASE_URL;
  const [contentLoading, setContentLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [header, setHeader] = useState('');
  const [features, setFeatures] = useState([]);
  const [featuresVideo, setFeaturesVideo] = useState({});
  const [featuresVideoPoster, setFeaturesVideoPoster] = useState({});
  const [videoOpen, setVideoOpen] = useState(false);
  const [videoPosterOpen, setVideoPosterOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleVideoOpen = () => setVideoOpen(true);
  const handleVideoClose = () => setVideoOpen(false);

  const handleVideoPosterOpen = () => setVideoPosterOpen(true);
  const handleVideoPosterClose = () => setVideoPosterOpen(false);

  const handleTitle = e => {
    const val = e.target.value;
    setTitle(val);
  };

  const handleHeader = e => {
    const val = e.target.value;
    setHeader(val);
  };

  const fetchConent = async () => {
    try {
      setContentLoading(true);
      const { data } = await axios.get(`${base}/about`, { params: { locale } });
      const { title, header, features, featuresVideo, featuresVideoPoster } = data;
      setTitle(title);
      setHeader(header);
      setFeatures(features);
      setFeaturesVideo(featuresVideo);
      setFeaturesVideoPoster(featuresVideoPoster);
    } catch (error) {
      console.log({ error });
    } finally {
      setContentLoading(false);
    }
  };

  useEffect(() => {
    fetchConent();
  }, []);

  const removeImage = async (file, type) => {
    const deleteTask = firebase.storage().ref().child(file).delete();
    await deleteTask
      .then(async () => {
        // complete function ....
        let obj;
        switch (type) {
          case 'feature':
            const ftrArr = features.filter(({ image }) => image.file !== file);
            obj = { featuresArr: ftrArr };
            break;
          case 'poster':
            obj = { featuresVideoPosterObj: {} };
            break;
          case 'video':
            obj = { featuresVideoObj: {} };
            break;
          default:
            break;
        }
        await handleSubmit(obj);
      })
      .catch(error => {
        // error function ....
        snackbar.error('Error Occured While Removing Image.');
        console.log(error);
      });
  };

  const handleSubmit = async ({
    featuresArr = features,
    featuresVideoObj = featuresVideo,
    featuresVideoPosterObj = featuresVideoPoster,
  } = {}) => {
    try {
      /**
       * Add Create Doctor Here
       */
      snackbar.toast('Data Saved Successfully');
      await axios.post(`${process.env.REACT_APP_BASE_URL}/about`, {
        features: featuresArr,
        title,
        header,
        featuresVideo: featuresVideoObj,
        featuresVideoPoster: featuresVideoPosterObj,
        locale,
      });
      await fetchConent();
    } catch (error) {
      console.log(error);
      snackbar.error(snackbarErrorMsg);
    }
  };

  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 3 },
        display: 'flex',
        flexDirection: 'column',
      }}
      autoComplete="off"
    >
      {contentLoading ? (
        <CircularProgress color="primary" size={80} sx={{ mx: 'auto' }} />
      ) : (
        <>
          <AddImageModal
            open={open}
            handleClose={handleClose}
            features={features}
            handleSubmit={handleSubmit}
          />
          <Box
            sx={{
              '& .MuiTextField-root': { m: 3, width: '50%' },
              display: 'flex',
              flexDirection: 'row',
            }}
            autoComplete="off"
          >
            <TextField
              required
              multiline
              onChange={handleHeader}
              value={header}
              rows={3}
              id="outlined-required-header"
              label="Header"
              placeholder="Header"
            />
            <TextField
              required
              id="outlined-required-title"
              label="Title"
              placeholder="Title"
              multiline
              rows={3}
              value={title}
              onChange={handleTitle}
            />
          </Box>
          <Divider textAlign="left">Cards</Divider>

          <Box
            sx={{
              m: 3,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {features.map(({ image, title }, idx) => (
              <ImageCardComponent
                component="img"
                key={idx}
                url={image.url}
                file={image.file}
                title={title}
                removeImage={removeImage}
                type="feature"
              />
            ))}

            <Box
              sx={{
                ml: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Fab color="primary" aria-label="add" onClick={handleOpen}>
                <AddIcon />
              </Fab>
            </Box>
          </Box>

          <Divider textAlign="left">Video</Divider>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '50%',
              }}
            >
              <Typography variant="h6" component="div">
                Video Poster
              </Typography>
              <Box
                sx={{
                  my: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {!_.isEmpty(featuresVideoPoster) ? (
                  <ImageCardComponent
                    component="img"
                    url={featuresVideoPoster?.url}
                    file={featuresVideoPoster?.file}
                    removeImage={removeImage}
                    type="poster"
                  />
                ) : (
                  <>
                    <Fab color="primary" aria-label="add" onClick={handleVideoPosterOpen}>
                      <AddIcon />
                    </Fab>
                    <CustomDropzone
                      attribute="featuresVideoPosterObj"
                      accept="image/*"
                      open={videoPosterOpen}
                      handleClose={handleVideoPosterClose}
                      handleSubmit={handleSubmit}
                    />
                  </>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '50%',
              }}
            >
              <Typography variant="h6" component="div">
                Video File
              </Typography>
              <Box
                sx={{
                  my: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {!_.isEmpty(featuresVideo) ? (
                  <ImageCardComponent
                    component="video"
                    url={featuresVideo?.url}
                    file={featuresVideo?.file}
                    removeImage={removeImage}
                    type="video"
                  />
                ) : (
                  <>
                    <Fab color="primary" aria-label="add" onClick={handleVideoOpen}>
                      <AddIcon />
                    </Fab>
                    <CustomDropzone
                      attribute="featuresVideoObj"
                      accept="video/*"
                      open={videoOpen}
                      handleClose={handleVideoClose}
                      handleSubmit={handleSubmit}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>

          <Divider />
          <CardActions sx={{ alignSelf: 'flex-end', mt: 2 }}>
            <Button
              size="large"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={!title || !header}
            >
              Save
            </Button>
          </CardActions>
        </>
      )}
    </Box>
  );
}

export default About;
