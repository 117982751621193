import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import firebase from 'config/firebase';
import snackbar from 'utils/snackbar';
import { snackbarErrorMsg } from 'config/constants';
import { CardContent, CircularProgress, Dialog, Typography } from '@material-ui/core';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import _ from 'lodash';

const ImageCardComponent = ({ file, url, removeImage, component, type }) => {
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  return (
    <Card sx={{ maxWidth: 300, width: 300, m: 2 }}>
      <CardMedia sx={{ height: 140 }} src={url} component={component} title="WhenToUse Image" />
      <CardActions>
        <Button size="small" onClick={() => setWarningDialogOpen(true)}>
          Remove
        </Button>
      </CardActions>
      <WarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        containedBtn="close"
        onProceed={() => removeImage(file, type)}
        title={`Remove ${type}?`}
        description={`You are about to Remove ${type}, this action is irreversible.`}
        closeLabel="Cancel"
        proceedLabel="Yes, Remove!"
      />
    </Card>
  );
};

const CustomCardComponent = ({ title, description, setReasons }) => {
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  return (
    <Card sx={{ maxWidth: 300, width: 300, m: 2 }}>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {title}
        </Typography>

        <Typography gutterBottom variant="p" component="div">
          {description}
        </Typography>
      </CardContent>

      <CardActions>
        <Button size="small" onClick={() => setWarningDialogOpen(true)}>
          Remove
        </Button>
      </CardActions>
      <WarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        containedBtn="close"
        onProceed={() => {
          setReasons(st => st.filter(res => _.toLower(res.title) !== _.toLower(title)));
          setWarningDialogOpen(false);
        }}
        title={`Remove Reason?`}
        description={`You are about to Remove Reason, this action is irreversible.`}
        closeLabel="Cancel"
        proceedLabel="Yes, Remove!"
      />
    </Card>
  );
};

const AddReasonModal = ({ open, handleClose, setReasons }) => {
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');

  const handleTitle = e => setTitle(e.target.value);
  const handleDescription = e => setDescription(e.target.value);

  // reasons.push({ title, image: { bucket, file, url } });
  // await handleSubmit({ featuresArr: reasons });
  // dispatch(endUpload());
  // handleClose();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          alignItems: 'center',
          width: 400,
        },
      }}
    >
      <Box
        sx={{
          '& .MuiTextField-root': { width: '100%' },
          display: 'flex',
          flexDirection: 'column',
        }}
        autoComplete="off"
      >
        <TextField
          sx={{ my: 2 }}
          required
          onChange={handleTitle}
          value={title}
          id="outlined-required-title"
          label="Title"
          placeholder="Title"
        />
        <TextField
          sx={{ my: 2 }}
          required
          multiline
          rows={3}
          onChange={handleDescription}
          value={description}
          id="outlined-required-description"
          label="Description"
          placeholder="Description"
        />
      </Box>
      <CardActions>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            setReasons(st => [...st, { title, description }]);
            handleClose();
          }}
          disabled={!description || !title}
        >
          Add
        </Button>
      </CardActions>
    </Dialog>
  );
};

function WhenToUse({ locale }) {
  const base = process.env.REACT_APP_BASE_URL;
  const [contentLoading, setContentLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [header, setHeader] = useState('');
  const [description, setDescription] = useState('');
  const [playstoreURL, setPlaystoreURL] = useState('');
  const [appstoreURL, setAppstoreURL] = useState('');
  const [reasons, setReasons] = useState([]);
  const [image, setImage] = useState({});
  const [imageOpen, setImageOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleVideoPosterOpen = () => setImageOpen(true);
  const handleVideoPosterClose = () => setImageOpen(false);

  const handleTitle = e => {
    const val = e.target.value;
    setTitle(val);
  };

  const handleHeader = e => {
    const val = e.target.value;
    setHeader(val);
  };

  const handleDescription = e => {
    const val = e.target.value;
    setDescription(val);
  };

  const handleGoogleLink = e => {
    const val = e.target.value;
    setPlaystoreURL(val);
  };

  const handleAppLink = e => {
    const val = e.target.value;
    setAppstoreURL(val);
  };

  const fetchConent = async () => {
    try {
      setContentLoading(true);
      const { data } = await axios.get(`${base}/apps`, { params: { locale } });
      const { title, header, reasons, image, appstoreURL, playstoreURL, description } = data;
      setTitle(title);
      setHeader(header);
      setAppstoreURL(appstoreURL);
      setPlaystoreURL(playstoreURL);
      setDescription(description);
      setReasons(reasons);
      setImage(image);
    } catch (error) {
      console.log({ error });
    } finally {
      setContentLoading(false);
    }
  };

  useEffect(() => {
    fetchConent();
  }, []);

  const removeImage = async (file, type) => {
    const deleteTask = firebase.storage().ref().child(file).delete();
    await deleteTask
      .then(async () => {
        await handleSubmit({ imageObj: {} });
      })
      .catch(error => {
        // error function ....
        snackbar.error('Error Occured While Removing Image.');
        console.log(error);
      });
  };

  const handleSubmit = async ({ imageObj = image } = {}) => {
    try {
      /**
       * Add Create Doctor Here
       */
      snackbar.toast('Data Saved Successfully');
      await axios.post(`${process.env.REACT_APP_BASE_URL}/apps`, {
        reasons,
        title,
        header,
        description,
        appstoreURL,
        playstoreURL,
        image: imageObj,
        locale,
      });
      await fetchConent();
    } catch (error) {
      console.log(error);
      snackbar.error(snackbarErrorMsg);
    }
  };

  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 3 },
        display: 'flex',
        flexDirection: 'column',
      }}
      autoComplete="off"
    >
      {contentLoading ? (
        <CircularProgress color="primary" size={80} sx={{ mx: 'auto' }} />
      ) : (
        <>
          <AddReasonModal open={open} handleClose={handleClose} setReasons={setReasons} />
          <Box
            sx={{
              '& .MuiTextField-root': { m: 3, width: '50%' },
              display: 'flex',
              flexDirection: 'row',
            }}
            autoComplete="off"
          >
            <TextField
              required
              multiline
              onChange={handleHeader}
              value={header}
              rows={3}
              id="outlined-required-header"
              label="Header"
              placeholder="Header"
            />
            <TextField
              required
              id="outlined-required-title"
              label="Title"
              placeholder="Title"
              multiline
              rows={3}
              value={title}
              onChange={handleTitle}
            />
          </Box>
          <Box
            sx={{
              '& .MuiTextField-root': { m: 3, width: '50%' },
              display: 'flex',
              flexDirection: 'row',
            }}
            autoComplete="off"
          >
            <TextField
              required
              onChange={handleGoogleLink}
              value={playstoreURL}
              id="outlined-required-google-link"
              label="Google Play Link"
              placeholder="Google Play Link"
            />
            <TextField
              required
              id="outlined-required-app-linl"
              label="App Store Link"
              placeholder="App Store Link"
              value={appstoreURL}
              onChange={handleAppLink}
            />
          </Box>
          <Box
            sx={{
              '& .MuiTextField-root': { m: 3, width: '100%' },
              display: 'flex',
              flexDirection: 'row',
            }}
            autoComplete="off"
          >
            <TextField
              required
              multiline
              onChange={handleDescription}
              value={description}
              rows={3}
              id="outlined-required-description"
              label="Description"
              placeholder="Description"
            />
          </Box>
          <Divider textAlign="left">Cards</Divider>

          <Box
            sx={{
              m: 3,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {reasons.map(({ description, title }, idx) => (
              <CustomCardComponent
                key={idx}
                title={title}
                description={description}
                setReasons={setReasons}
              />
            ))}

            <Box
              sx={{
                ml: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Fab color="primary" aria-label="add" onClick={handleOpen}>
                <AddIcon />
              </Fab>
            </Box>
          </Box>

          <Divider textAlign="left">Image</Divider>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '50%',
              }}
            >
              <Typography variant="h6" component="div">
                When To Use Image
              </Typography>
              <Box
                sx={{
                  my: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {!_.isEmpty(image) ? (
                  <ImageCardComponent
                    component="img"
                    url={image?.url}
                    file={image?.file}
                    removeImage={removeImage}
                    type="image"
                  />
                ) : (
                  <>
                    <Fab color="primary" aria-label="add" onClick={handleVideoPosterOpen}>
                      <AddIcon />
                    </Fab>
                    <CustomDropzone
                      prefix="apps"
                      attribute="imageObj"
                      accept="image/*"
                      open={imageOpen}
                      handleClose={handleVideoPosterClose}
                      handleSubmit={handleSubmit}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>

          <Divider />
          <CardActions sx={{ alignSelf: 'flex-end', mt: 2 }}>
            <Button
              size="large"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={!title || !header || !appstoreURL || !playstoreURL}
            >
              Save
            </Button>
          </CardActions>
        </>
      )}
    </Box>
  );
}

export default WhenToUse;
