import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/core/Alert';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import firebase from 'config/firebase';
import axios from 'axios';
import snackbar from 'utils/snackbar';
import { snackbarErrorMsg } from 'config/constants';
import { useDispatch } from 'react-redux';
import { endUpload, setProgress, startUpload } from 'redux/features/upload.slice';
import { Avatar } from '@material-ui/core';
import { Formik } from 'formik';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px 20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: 'rgba(0,0,0,60)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

function AddOrEditDoctor({ open, setOpen, setDoctors, doctor }) {
  const [doctorImage, setDoctorImage] = useState(null);

  const dispatch = useDispatch();
  const form = useRef(null);

  const uploadImage = async () => {
    dispatch(startUpload());
    const uploadTask = firebase
      .storage()
      .ref(`doctors/${Date.now()}.${doctorImage?.name.split('.').pop()}`)
      .put(doctorImage);
    uploadTask.on(
      'state_changed',
      snapshot => {
        // progrss function ....
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        dispatch(setProgress(progress));
      },
      error => {
        // error function ....
        console.log(error);
      },
    );
    const uploadResult = await uploadTask;
    const bucket = firebase.storage().ref().bucket;
    const file = uploadResult.metadata.name;
    const url = await uploadResult.ref.getDownloadURL();
    dispatch(endUpload());
    return {
      bucket,
      file,
      url,
    };
  };

  const handleSubmit = async (
    { name, name_ar, title, title_ar, services, services_ar, service, service_ar },
    { setSubmitting },
  ) => {
    try {
      setSubmitting(true);
      const requestBody = { name, name_ar, title, title_ar, services, services_ar };
      if (doctorImage) {
        requestBody.image = await uploadImage();
      } else if (doctor) {
        requestBody.image = doctor.image;
      }

      if (doctor) {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/doctors/${doctor.id}`,
          requestBody,
        );
        setDoctors(prev => {
          const newDoctors = [...prev];
          const index = newDoctors.findIndex(d => d.id === doctor.id);
          newDoctors[index] = { id: doctor.id, ...requestBody };
          return newDoctors;
        });
      } else {
        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/doctors`, requestBody);
        setDoctors(prev => [data.doctor, ...prev]);
        snackbar.toast('New Doctor Added Successfully');
      }
      form.current?.resetForm();
      setOpen(false);
    } catch (error) {
      console.log(error);
      snackbar.error(snackbarErrorMsg);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (!open) {
      setDoctorImage(undefined);
      form.current?.resetForm();
    }
  }, [open]);
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setDoctorImage(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
  });

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="add-doctor-title"
      aria-describedby="add-doctor-description"
    >
      <Formik
        onSubmit={handleSubmit}
        innerRef={form}
        validateOnMount={true}
        validateOnChange={true}
        validate={({ name, name_ar, title, title_ar, services, services_ar }) => {
          const errors = {};
          if (!name || !name_ar || !title || !title_ar || !services || !services_ar) {
            errors.error = 'All fields are required';
          }
          return errors;
        }}
        initialValues={{
          name: doctor?.name || '',
          name_ar: doctor?.name_ar || '',
          title: doctor?.title || '',
          title_ar: doctor?.title_ar || '',
          services: doctor?.services || [],
          service: '',
          service_ar: '',
          services_ar: doctor?.services_ar || [],
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isValid,
          isSubmitting,
          submitForm,
        }) => (
          <>
            <DialogTitle id="add-doctor-title">Add New Doctor</DialogTitle>
            <DialogContent>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 5 }}>
                {doctor && (
                  <Avatar
                    alt={doctor.name}
                    src={doctor.image.url}
                    sx={{ width: 150, height: 150 }}
                  />
                )}
              </Box>

              <form onSubmit={handleSubmit}>
                <TextField
                  sx={{ mb: 2 }}
                  autoFocus
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="name"
                  label="Doctor Name"
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  sx={{ mb: 2 }}
                  name="name_ar"
                  value={values.name_ar}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="name_ar"
                  label="Doctor Name in Arabic"
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  sx={{ mb: 2 }}
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="title"
                  label="Title"
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  sx={{ mb: 2 }}
                  name="title_ar"
                  value={values.title_ar}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="title_ar"
                  label="Arabic Title"
                  fullWidth
                  variant="outlined"
                />

                <Box sx={{ mb: 1, display: 'flex' }}>
                  <TextField
                    sx={{ mr: 1 }}
                    id="service"
                    label="Service"
                    name="service"
                    value={values.service}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant="outlined"
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        setFieldValue('services', [...values.services, values.service]);
                        setFieldValue('service', '');
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      setFieldValue('services', [...values.services, values.service]);
                      setFieldValue('service', '');
                    }}
                    disabled={!values.service.length || isSubmitting}
                  >
                    Add
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 3 }}>
                  {values.services.map((service, i) => (
                    <Chip
                      key={i}
                      label={service}
                      onDelete={() => {
                        setFieldValue(
                          'services',
                          values.services.filter(s => s !== service),
                        );
                      }}
                      sx={{ m: 0.4 }}
                    />
                  ))}
                </Box>

                <Box sx={{ mb: 1, display: 'flex' }}>
                  <TextField
                    sx={{ mr: 1 }}
                    id="service_ar"
                    label="Service in Arabic"
                    name="service_ar"
                    value={values.service_ar}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant="outlined"
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        setFieldValue('services_ar', [...values.services_ar, values.service_ar]);
                        setFieldValue('service_ar', '');
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      setFieldValue('services_ar', [...values.services_ar, values.service_ar]);
                      setFieldValue('service_ar', '');
                    }}
                    disabled={!values.service_ar.length || isSubmitting}
                  >
                    Add
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 3 }}>
                  {values.services_ar.map((service, i) => (
                    <Chip
                      key={i}
                      label={service}
                      onDelete={() => {
                        setFieldValue(
                          'services_ar',
                          values.services_ar.filter(s => s !== service),
                        );
                      }}
                      sx={{ m: 0.4 }}
                    />
                  ))}
                </Box>
              </form>

              <Box sx={{ mb: 2, minWidth: { md: 520 } }}>
                <Collapse in={!doctorImage} timeout={400} mountOnEnter unmountOnExit>
                  <div className="container">
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the image here ...</p>
                      ) : (
                        <p>Drag 'n' drop doctor's profile image here, or click to select it</p>
                      )}
                    </div>
                  </div>
                </Collapse>
                <Collapse in={!!doctorImage} timeout={400} mountOnEnter unmountOnExit>
                  <Alert
                    severity="success"
                    sx={{
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.08)',
                      boxShadow: 1,
                      p: 2,
                      color: 'text.primary',
                      '& .MuiAlert-message': {
                        color: 'inherit',
                        fontWeight: 'bold',
                      },
                      '& .MuiAlert-icon': {
                        color: 'inherit',
                      },
                      '&:hover': {
                        boxShadow: 3,
                      },
                    }}
                    variant="outlined"
                    onClose={() => setDoctorImage(null)}
                  >
                    {doctorImage?.name}
                  </Alert>
                </Collapse>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button
                onClick={() => submitForm()}
                disabled={isSubmitting || !isValid || (!doctorImage && !doctor?.image)}
                variant="contained"
              >
                {(doctor ? 'Edit' : 'Add') + ' Doctor'}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}

export default AddOrEditDoctor;
