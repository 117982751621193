import Dashboard from 'pages/Dashboard/Dashboard';
import AuthPage from 'pages/AuthPage/AuthPage';
import React from 'react';
import Content from 'pages/Content/Content';
import Messages from 'pages/Messages/Messages';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: '/login',
    isPrivate: false,
    exact: true,
    component: <AuthPage />,
    tab: null,
  },
  {
    path: '/',
    isPrivate: true,
    exact: true,
    component: <Dashboard />,
    tab: {
      label: 'Dashboard',
      icon: 'dashboard',
    },
  },
  {
    path: '/content',
    isPrivate: true,
    exact: true,
    component: <Content />,
    tab: {
      label: 'Manage Content',
      icon: 'dashboard',
    },
  },
  {
    path: '/messages',
    isPrivate: true,
    exact: true,
    component: <Messages />,
    tab: {
      label: 'Messages',
      icon: 'dashboard',
    },
  },
];
