import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import snackbar from 'utils/snackbar';
import { snackbarErrorMsg } from 'config/constants';
import CircularProgress from '@material-ui/core/CircularProgress';

function Doctors({ locale }) {
  const base = process.env.REACT_APP_BASE_URL;
  const [contentLoading, setContentLoading] = useState(true);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [phone, setPhone] = useState('');

  const handleDescription = e => {
    const val = e.target.value;
    setDescription(val);
  };
  const handleTitle = e => {
    const val = e.target.value;
    setTitle(val);
  };
  const handlePhone = e => {
    const val = e.target.value;
    setPhone(val);
  };

  const fetchConent = async () => {
    try {
      setContentLoading(true);
      const { data } = await axios.get(`${base}/doctorsDetails`, { params: { locale } });
      const { description, phone, title } = data;
      setPhone(phone);
      setDescription(description);
      setTitle(title);
    } catch (error) {
      console.log({ error });
    } finally {
      setContentLoading(false);
    }
  };

  useEffect(() => {
    fetchConent();
  }, []);

  const handleSubmit = async () => {
    try {
      /**
       * Add Create Doctor Here
       */
      await axios.post(`${process.env.REACT_APP_BASE_URL}/doctorsDetails`, {
        description,
        phone,
        title,
        locale,
      });
      snackbar.toast('Data Saved Successfully');
      await fetchConent();
    } catch (error) {
      console.log(error);
      snackbar.error(snackbarErrorMsg);
    }
  };

  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 3 },
        display: 'flex',
        flexDirection: 'column',
      }}
      autoComplete="off"
    >
      {contentLoading ? (
        <CircularProgress color="primary" size={80} sx={{ mx: 'auto' }} />
      ) : (
        <>
          <Box
            sx={{
              '& .MuiTextField-root': { m: 3, width: '100%' },
              display: 'flex',
              flexDirection: 'row',
            }}
            autoComplete="off"
          >
            <TextField
              required
              onChange={handleTitle}
              value={title}
              id="outlined-required-title"
              label="Title"
              placeholder="Title"
            />

            <TextField
              required
              onChange={handlePhone}
              value={phone}
              id="outlined-required-phone"
              label="Phone"
              placeholder="Phone"
            />
          </Box>
          <Box
            sx={{
              '& .MuiTextField-root': { m: 3, width: '100%' },
              display: 'flex',
              flexDirection: 'row',
            }}
            autoComplete="off"
          >
            <TextField
              required
              multiline
              onChange={handleDescription}
              value={description}
              rows={3}
              id="outlined-required-description"
              label="Description"
              placeholder="Description"
            />
          </Box>

          <Divider />
          <CardActions sx={{ alignSelf: 'flex-end', mt: 2 }}>
            <Button
              size="large"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={!title || !description || !phone}
            >
              Save
            </Button>
          </CardActions>
        </>
      )}
    </Box>
  );
}

export default Doctors;
