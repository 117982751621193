import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import firebase from 'config/firebase';
import snackbar from 'utils/snackbar';
import { snackbarErrorMsg } from 'config/constants';
import { Alert, CircularProgress, Collapse, Dialog } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { endUpload, setProgress, startUpload } from 'redux/features/upload.slice';
import WarningDialog from 'components/WarningDialog/WarningDialog';

const ImageCardComponent = ({ file, url, removeImage }) => {
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  return (
    <Card sx={{ maxWidth: 300, width: 300, m: 2 }}>
      <CardMedia sx={{ height: 140 }} image={url} title="Home Image" />
      <CardActions>
        <Button size="small" onClick={() => setWarningDialogOpen(true)}>
          Remove
        </Button>
      </CardActions>
      <WarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        containedBtn="close"
        onProceed={() => removeImage(file)}
        title="Remove Image?"
        description="You are about to Remove an Image, this action is irreversible."
        closeLabel="Cancel"
        proceedLabel="Yes, Remove!"
      />
    </Card>
  );
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px 20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: 'rgba(0,0,0,60)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const AddImageModal = ({ open, handleClose, images, handleSubmit }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const file = `home/${new Date().getTime()}.${image?.name.split('.').pop()}`;

  const uploadImage = () => {
    dispatch(startUpload());
    const uploadTask = firebase.storage().ref(file).put(image);
    uploadTask.on(
      'state_changed',
      snapshot => {
        // progrss function ....
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        dispatch(setProgress(progress));
      },
      error => {
        // error function ....
        snackbar.error('Error Occured While Uploading.');
        console.log(error);
      },
      async () => {
        // complete function ....
        const bucket = firebase.storage().ref().bucket;
        const url = await (await uploadTask).ref.getDownloadURL();
        images.push({ bucket, file, url });
        // setImages(st => [...st, { bucket, file, url }]);
        await handleSubmit(images);
        dispatch(endUpload());
        handleClose();
      },
    );
  };

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setImage(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          alignItems: 'center',
        },
      }}
    >
      <Box sx={{ mb: 2, minWidth: { md: 520 } }}>
        <Collapse in={!image} timeout={400} mountOnEnter unmountOnExit>
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the image here ...</p>
              ) : (
                <p>Drag 'n' drop image here, or click to select it</p>
              )}
            </div>
          </div>
        </Collapse>
        <Collapse in={!!image} timeout={400} mountOnEnter unmountOnExit>
          <Alert
            severity="success"
            sx={{
              border: '1px solid',
              borderColor: 'rgba(0,0,0,0.08)',
              boxShadow: 1,
              p: 2,
              color: 'text.primary',
              '& .MuiAlert-message': {
                color: 'inherit',
                fontWeight: 'bold',
              },
              '& .MuiAlert-icon': {
                color: 'inherit',
              },
              '&:hover': {
                boxShadow: 3,
              },
            }}
            variant="outlined"
            onClose={() => setImage(null)}
          >
            {image?.name}
          </Alert>
        </Collapse>
      </Box>
      <CardActions>
        <Button size="large" variant="contained" onClick={() => uploadImage()} disabled={!image}>
          Upload
        </Button>
      </CardActions>
    </Dialog>
  );
};

function Home({ locale }) {
  const base = process.env.REACT_APP_BASE_URL;
  const [contentLoading, setContentLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTitle = e => {
    const val = e.target.value;
    setTitle(val);
  };

  const handleDescription = e => {
    const val = e.target.value;
    setDescription(val);
  };

  const fetchConent = async () => {
    try {
      setContentLoading(true);
      const { data } = await axios.get(`${base}/home`, { params: { locale } });
      const { title, description, images } = data;
      setTitle(title);
      setDescription(description);
      setImages(images);
    } catch (error) {
      console.log(error);
    } finally {
      setContentLoading(false);
    }
  };

  useEffect(() => {
    fetchConent();
  }, []);

  const removeImage = async file => {
    const deleteTask = firebase.storage().ref().child(file).delete();
    await deleteTask
      .then(async () => {
        // complete function ....
        const imgArr = images.filter(img => img.file !== file);
        // setImages(st => [...st, { bucket, file, url }]);
        await handleSubmit(imgArr);
      })
      .catch(error => {
        // error function ....
        snackbar.error('Error Occured While Removing Image.');
        console.log(error);
      });
  };

  const handleSubmit = async (imagesArr = images) => {
    try {
      /**
       * Add Create Doctor Here
       */
      snackbar.toast('Data Saved Successfully');
      await axios.post(`${process.env.REACT_APP_BASE_URL}/home`, {
        images: imagesArr,
        title,
        description,
        locale,
      });
      await fetchConent();
    } catch (error) {
      console.log(error);
      snackbar.error(snackbarErrorMsg);
    }
  };

  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 3 },
        display: 'flex',
        flexDirection: 'column',
      }}
      autoComplete="off"
    >
      {contentLoading ? (
        <CircularProgress color="primary" size={80} sx={{ mx: 'auto' }} />
      ) : (
        <>
          <AddImageModal
            open={open}
            handleClose={handleClose}
            // setImages={setImages}
            images={images}
            handleSubmit={handleSubmit}
          />
          <Box
            sx={{
              '& .MuiTextField-root': { m: 3, width: '50%' },
              display: 'flex',
              flexDirection: 'row',
            }}
            autoComplete="off"
          >
            <TextField
              required
              id="outlined-required-title"
              label="Title"
              placeholder="Title"
              multiline
              rows={3}
              value={title}
              onChange={handleTitle}
            />
            <TextField
              required
              multiline
              onChange={handleDescription}
              value={description}
              rows={3}
              id="outlined-required-description"
              label="Description"
              placeholder="Description"
            />
          </Box>
          <Divider textAlign="left">Images</Divider>

          <Box
            sx={{
              m: 3,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {images.map((img, idx) => (
              <ImageCardComponent
                key={idx}
                url={img.url}
                file={img.file}
                removeImage={removeImage}
              />
            ))}
            {images.length < 4 && (
              <Box
                sx={{
                  ml: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Fab color="primary" aria-label="add" onClick={handleOpen}>
                  <AddIcon />
                </Fab>
              </Box>
            )}
          </Box>
          <Divider />
          <CardActions sx={{ alignSelf: 'flex-end', mt: 2 }}>
            <Button
              size="large"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={!title || !description || !images}
            >
              Save
            </Button>
          </CardActions>
        </>
      )}
    </Box>
  );
}

export default Home;
