import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: null,
  isAuth: !!JSON.parse(localStorage.getItem('isAuth')),
  loading: false,
  errors: {},
};

const authSlice = createSlice({
  name:'auth',
  initialState,
  reducers: {
    startAuth: (state, action) => ({
      ...state,
      loading: true,
      errors: {},
    }),
    authUserSuccess: (state, action) => ({
      ...state,
      loading: false,
      isAuth: true,
      currentUser: action.payload,
    }),
    logoutUser: (state, action) => ({
      ...state,
      currentUser: null,
      loading: false,
      isAuth: false,
    }),
    authFail: (state, action) => ({
      ...state,
      loading: false,
      errors: action.payload,
    }),
    checkAuth: (state, action) => state,
  },
});

export const { startAuth, authUserSuccess, logoutUser, authFail,checkAuth } = authSlice.actions;

export default authSlice.reducer;
